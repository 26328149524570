import { qrUrl } from "Configs/config"
import { useCommonEntitiesStore } from "States/commonEntities"
import ErrorNotice from "components/ErrorNotice"
import PrintableQRCode from "components/Print/PrintableQRCode"
import { A4_WIDTH, createPrintStyles, qrPrintStyles } from "pages/qr/qrPrintStyles"
import { useEffect, useState } from "react"
import { IContainer } from "admin-client-server/src/router"
import { chunk } from "lodash"
import { useTrans } from "translations"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { Button } from "components/button"
import QRPrintPage from "./QRPrintPage"
import { useTerminalsState } from "States/Terminals"
import { useQRData } from "../useQRData"
import { EmptyView } from "components/EmptyView/EmptyView"
import { useConfigService } from "pages/configuration/useConfigService"

type PrintableContainer = IContainer & {
	imgSrc: string
	primaryLabel: string
	englishLabel: string
	customColor?: string
}

interface Props {
	selectedTemplate: string
}

function removeDuplicateWasteTypeContainers(containers: PrintableContainer[]) {
	if (!containers || containers.length === 0) return []

	const uniqueContainers = Array.from(
		new Set(containers.map(container => container?.fraction_id))
	).map(fraction_id => containers.find(container => container?.fraction_id === fraction_id))

	return uniqueContainers as PrintableContainer[]
}

const QRCodeDisplayCard: React.FC<Props> = ({ selectedTemplate }) => {
	const [visibleForPrint, setVisibleForPrint] = useState(false)
	const visibleForPrintClass = visibleForPrint ? "print:visible" : "print:hidden"
	const currentStyle = qrPrintStyles.find(style => style.name === selectedTemplate)

	const { t } = useTrans()
	const { wasteTypes } = useCommonEntitiesStore()
	const { containers, isFetching, isError } = useQRData()
	const { wasteTypeConfig } = useConfigService()

	const { currentTerminal } = useTerminalsState()
	const pageLabel = currentTerminal?.name || ""

	useEffect(() => {
		if (visibleForPrint && currentStyle) {
			createPrintStyles(currentStyle)
			window.print()
			setVisibleForPrint(false)
		}
	}, [currentStyle, visibleForPrint])

	if (!currentStyle || (!isFetching && !containers)) return null

	const {
		columns,
		title,
		description,
		pageInfo,
		buttonText,
		previewText,
		maxCellsPerPage,
		iconSizeFactor,
		templateWidth,
		templateHeight,
		labelFontSize,
		gridCellheight,
		qrContainerMarginBottom,
		qrContainerHeightPercentage,
		qrLabelHeightPercentage,
		gridTemplateRows,
		allowDuplicateWasteTypes,
	} = currentStyle
	const iconSize = (A4_WIDTH / columns) * iconSizeFactor

	// Combine all containers from all stations into one array (and remove duplicates if needed)
	const containersToPrint = allowDuplicateWasteTypes
		? (containers as PrintableContainer[])
		: removeDuplicateWasteTypeContainers(containers as PrintableContainer[])

	const customizedContainers = containersToPrint
		.map(container => {
			const wasteTypeConfigItem = wasteTypeConfig.find(
				config => config.wasteTypeCode === container.fraction_id
			)
			return {
				...container,
				imgSrc: wasteTypeConfigItem?.color ? "" : container?.imgSrc,
				primaryLabel: wasteTypeConfigItem?.name || container?.primaryLabel || "",
				customColor: wasteTypeConfigItem?.color || "",
				handledBySmartInfra: wasteTypeConfigItem?.handledBySmartInfra,
			}
		})
		.filter(c => !c?.handledBySmartInfra)

	const pagesToPrint = chunk(customizedContainers, maxCellsPerPage)

	const handlePrint = () => {
		setVisibleForPrint(true)
	}

	return (
		<div className="w-[calc(50%-12px)]">
			<div className="bg-grey1 px-6 py-8 mb-12 mt-6 min-h-[300px] relative">
				<p className="font-medium mb-2">{t(title)}</p>
				<p className="mb-6 text-base">{t(description)}</p>
				<p className="mb-10">{t(pageInfo)}</p>
				<div className="absolute bottom-8 left-1/2 transform -translate-x-1/2">
					<Button label={t(buttonText)} onClick={handlePrint} className="text-lg" />
				</div>
			</div>
			<div className="text-lg font-medium mb-4">{t(previewText)}</div>
			<div className="border border-gray-300 p-2">
				<div
					className={`w-full qr-print-wrapper origin-top print:origin-top-left print:scale-100 ${visibleForPrintClass}`}
				>
					{isError && <ErrorNotice />}
					{customizedContainers?.length > 0 &&
						pagesToPrint.map((page, pageIndex) => (
							<QRPrintPage
								key={pageIndex}
								pageIndex={pageIndex}
								pageLabel={pageLabel}
								qrContainerMarginBottom={qrContainerMarginBottom}
								templateHeight={templateHeight}
								templateWidth={templateWidth}
								columns={columns}
								gridTemplateRows={gridTemplateRows}
							>
								{wasteTypes &&
									page.map(
										container =>
											container?.station_id && (
												<PrintableQRCode
													container={container}
													qrUrl={qrUrl}
													stationId={container.station_id}
													key={container.id}
													qrContainerHeightPercentage={qrContainerHeightPercentage}
													qrLabelHeightPercentage={qrLabelHeightPercentage}
													iconSize={iconSize}
													labelFontSize={labelFontSize}
													gridCellheight={gridCellheight}
												/>
											)
									)}
							</QRPrintPage>
						))}
				</div>
				{isFetching ? (
					<div className="flex justify-center items-center min-h-full h-full">
						<LoadingIndicator />
					</div>
				) : (
					!containersToPrint?.length && (
						<div className="flex justify-center h-[500px]">
							<EmptyView type="qrCode" />
						</div>
					)
				)}
			</div>
		</div>
	)
}

export default QRCodeDisplayCard
