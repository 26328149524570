import { sum } from "lodash"
import { twMerge } from "tailwind-merge"
import { useTrans } from "translations"
import { TenantType } from "."
import { getSortedWasteTypes } from "./exportExcel"
import { useCommonEntitiesStore } from "States/commonEntities"
import { formatNumberForTable, formatWeight } from "Utils/formatFunctions"
import { Header } from "components/TableWithSelect"
import { useConfigService } from "pages/configuration/useConfigService"

export const StickyFooterComponent = ({
	tableRef,
	headers,
	filteredWasteTypes,
	tenants,
	currentTerminalName,
}: {
	tableRef: HTMLTableElement
	headers: Header[]
	filteredWasteTypes: string[]
	tenants: TenantType[]
	currentTerminalName: string
}) => {
	const { t } = useTrans()
	const { wasteTypes } = useCommonEntitiesStore()
	const { wasteTypeConfig } = useConfigService()

	const allTypesSums = getSortedWasteTypes(
		filteredWasteTypes,
		wasteTypeConfig,
		wasteTypes || []
	).map(code => sum(tenants.map(el => formatWeight(el[code]) || 0)))
	const allTypesTotal = sum(allTypesSums)

	const totalRow = [...allTypesSums, allTypesTotal]

	return (
		<tfoot
			className="sticky bottom-0 h-12 font-medium text-sm py-3 bg-grey1 before:absolute before:top-[-1px] before:left-0 before:w-full before:border-t before:border-black
                 after:absolute after:bottom-[1px] after:left-0 after:w-full after:border-b after:border-black"
		>
			<tr>
				<td>{/* Checkbox */}</td>
				<td className="pl-2 sticky left-0 bg-grey1 h-12 flex items-center">
					{t("genericLabels:total")} {currentTerminalName} (kg)
				</td>
				{totalRow.map((val, i, arr) => (
					<td
						key={i}
						className={twMerge(
							" justify-end text-end last:sticky last:right-0 last:bg-grey1 h-full bg-grey1 pr-8",
							i === arr.length - 1 && "pr-4"
						)}
					>
						{formatNumberForTable(val)}
					</td>
				))}
			</tr>
		</tfoot>
	)
}
