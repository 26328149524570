import { Page } from "components/Page"
import { TenantWasteTypesCard } from "components/pageCards/tenantWasteTypesCard"
import { FC } from "react"
import { useTerminalsState } from "States/Terminals"

export const TenantWasteTypes: FC<{}> = () => {
	const { currentTerminal } = useTerminalsState()

	return (
		<Page title="statisticsLabels:tenantWasteTypes" fullHeight>
			<TenantWasteTypesCard realEstateId={currentTerminal.id} />
		</Page>
	)
}

export default TenantWasteTypes
