import {
	ColumnDef,
	ColumnSort,
	Row,
	SortingState,
	getCoreRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table"
import { useVirtualizer } from "@tanstack/react-virtual"
import classNames from "classnames"
import { isEqual, noop } from "lodash"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { TableBody } from "./components/TableBody"
import { TableContext } from "./TableContext"
import { useTrans } from "translations"
import { TableHeader } from "./components/TableHeader"
import { getColumns } from "./utils/getColumns"
import { TableFooter } from "./components/TableFooter"
import { TableSkeleton } from "./components/TableSkeleton"
import { twMerge } from "tailwind-merge"
import { TableCellRenderFunctionsType } from "./utils/cellRenderFunctions"

export type DataTableDataType = string | number
export type Header = {
	key: string
	title: string
	translate?: boolean
	defaultSize?: number
	rightAlign?: boolean
	sortable?: boolean
	stickyLeft?: boolean
	stickyRight?: boolean
	renderFunction?: TableCellRenderFunctionsType[string]
}

export type ExpandedColumnDef = ColumnDef<DataTableDataType> & {
	rightAlign?: boolean
	stickyLeft?: boolean
	stickyRight?: boolean
	accessorKey: string
}

export type Pagination = {
	fetchNextPage: () => void
	hasNextPage?: boolean
	isFetchingNextPage: boolean
	isRefetching?: boolean
}

type TableProps = {
	data?: { [k: string]: any }[]
	defaultSortingHeader?: ColumnSort
	selectedIds?: string[]
	setSelectedIds?: (ids: string[]) => void
	isLoading?: boolean
	onRowClick?: (rowData: any) => void
	onRowSelectionChange?: (rowSelection: Record<number, boolean>) => void
	headers?: Header[]
	useCheckboxes?: boolean
	hasMultipleSelectFunctionality?: boolean
	hasExportText?: boolean
	showExportButton?: boolean
	labelSingle?: string
	labelMultiple?: string
	showFooter?: boolean
	getStickyFooterComponent?: (ref: HTMLTableElement) => JSX.Element
	stickyCheckbox?: boolean
	updateSorting?: (sorting: SortingState) => void
	pagination?: Pagination
	onAllSelected?: (val: boolean) => void
	useActions?: boolean
	onEdit?: (row: any) => void
	onDelete?: (id: string) => void
}

export const TableWithSelect: React.FC<TableProps> = ({
	data = [],
	defaultSortingHeader,
	selectedIds = [],
	setSelectedIds = () => {},
	isLoading,
	onRowClick = () => {},
	onRowSelectionChange = () => {},
	headers = [],
	useCheckboxes = true,
	hasMultipleSelectFunctionality = true,
	hasExportText = true,
	showExportButton = true,
	labelSingle = "",
	labelMultiple = "",
	showFooter: shouldShowFooter = true,
	getStickyFooterComponent = noop,
	stickyCheckbox = false,
	updateSorting = noop,
	pagination,
	onAllSelected = () => {},
	useActions,
	onEdit,
	onDelete,
}) => {
	const { t } = useTrans()
	const tableRef = useRef<HTMLTableElement | null>(null)
	const tableContainerRef = useRef<HTMLDivElement | null>(null)
	const skeletonWrapperRef = useRef<HTMLDivElement | null>(null)
	const [selectedRows, setSelectedRows] = useState({})
	const [isSelectedRowLoading, setIsSelectedLoading] = useState(selectedIds.length === 1)
	const [sorting, setSorting] = useState<SortingState>(
		defaultSortingHeader ? [defaultSortingHeader] : []
	)

	useEffect(() => {
		updateSorting(sorting)
	}, [sorting, updateSorting])

	const columns = useMemo<ColumnDef<any>[]>(
		() =>
			getColumns({
				headers,
				data,
				t,
				selectedIds,
				setSelectedIds,
				useCheckboxes,
				stickyCheckbox,
				onAllSelected,
				useActions,
				onEdit,
				onDelete,
			}),
		[
			data,
			headers,
			t,
			selectedIds,
			setSelectedIds,
			useCheckboxes,
			stickyCheckbox,
			onAllSelected,
			useActions,
			onEdit,
			onDelete,
		]
	)

	const setRowSelection = useCallback(
		(updaterFn: any) => {
			const updatedSelectedRows = updaterFn(selectedRows)
			if (!isEqual(selectedRows, updatedSelectedRows)) {
				setSelectedRows(updaterFn)
				onRowSelectionChange(updatedSelectedRows)
			}
		},
		[selectedRows, onRowSelectionChange]
	)

	const table = useReactTable({
		data,
		columns,
		columnResizeMode: "onEnd",
		state: {
			rowSelection: selectedRows,
			sorting,
		},
		enableSortingRemoval: false,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: pagination ? undefined : getSortedRowModel(),
		onRowSelectionChange: setRowSelection,
		onSortingChange: setSorting,
	})

	const rows = table.getRowModel().rows

	// Select rows based on the selectedIds context
	useEffect(() => {
		rows.forEach(row => {
			const shouldToggle = selectedIds.includes(row.original.id as string)
			row.toggleSelected(shouldToggle)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedIds, rows])

	const virtualizerInstance = useVirtualizer({
		count: rows.length,
		estimateSize: () => 50, // row height
		getScrollElement: () => tableContainerRef.current,
		overscan: 5,
	})

	const onRowClickFunction = useCallback(
		(row: Row<any>) => {
			row.toggleSelected()
			onRowClick(row.original)
		},
		[onRowClick]
	)

	const contextValue = useMemo(
		() => ({
			onRowClick: onRowClickFunction,
			selectedIds,
		}),
		[onRowClickFunction, selectedIds]
	)

	// Called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
	const fetchMoreOnBottomReached = React.useCallback(
		(containerRefElement?: HTMLDivElement | null) => {
			if (!pagination) return
			if (containerRefElement) {
				const { scrollHeight, scrollTop, clientHeight } = containerRefElement
				// Once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
				if (
					scrollHeight - scrollTop - clientHeight < 300 &&
					!pagination.isRefetching &&
					!pagination.isFetchingNextPage &&
					pagination.hasNextPage
				) {
					pagination.fetchNextPage()
				}
			}
		},
		[pagination]
	)

	useEffect(() => {
		if (selectedIds.length === 1) {
			const index = rows.findIndex(el => el.original.id === selectedIds[0])

			const selectedRow = rows.find(el => el.getIsSelected())

			if (index >= 0 && selectedRow?.original.id !== selectedIds[0]) {
				virtualizerInstance.scrollToIndex(index)
				setIsSelectedLoading(false)
			} else if (index < 0 && pagination?.hasNextPage === false) {
				setIsSelectedLoading(false)
			}
		}
	}, [selectedIds, rows, virtualizerInstance, pagination?.hasNextPage])

	const stickyFooter = getStickyFooterComponent(tableRef.current)

	const showFooter = useMemo(() => {
		if (!!pagination) {
			return shouldShowFooter && (table.getSelectedRowModel().rows.length > 0 || hasExportText)
		}
		return shouldShowFooter
	}, [shouldShowFooter, table, hasExportText, pagination])

	return (
		<TableContext.Provider value={contextValue}>
			<div
				className={twMerge(
					`grid h-full grid-rows-[1fr,64px] p-5`,
					!showFooter && "grid-rows-[1fr]"
				)}
			>
				<div
					className={classNames(
						"h-full w-full flex-col overflow-auto",
						(isLoading || isSelectedRowLoading) && "relative overflow-hidden"
					)}
					ref={tableContainerRef}
					onScroll={e => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
				>
					{!isLoading && !!rows.length && (
						<>
							<table
								className="min-w-full table-fixed relative"
								{...{
									ref: tableRef,
								}}
							>
								<TableHeader headers={table.getHeaderGroups()} />
								{!isLoading && <TableBody rows={rows} virtualizerInstance={virtualizerInstance} />}
								{!isLoading && <>{stickyFooter}</>}
							</table>
						</>
					)}
					{(isLoading || isSelectedRowLoading) && (
						<div className="absolute bg-white h-full w-full leading-tight" ref={skeletonWrapperRef}>
							<TableSkeleton wrapperRef={skeletonWrapperRef} />
						</div>
					)}
				</div>
				{showFooter && (
					<TableFooter
						numberTotalRows={table.getPreFilteredRowModel().rows.length}
						numberRowsSelected={table.getSelectedRowModel().rows.length}
						{...{
							labelMultiple,
							labelSingle,
							hasExportText,
							hasMultipleSelectFunctionality,
							showExportButton,
							isPaginated: !!pagination,
							hasLoadedEverything: !pagination?.hasNextPage,
						}}
					/>
				)}
			</div>
		</TableContext.Provider>
	)
}
