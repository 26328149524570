import { IImportContext } from "components/ImportComponents/ImportContext"
import {
	ACCESS_PARENT_KEY,
	ACCESS_PARENT_NAME_KEY,
	CONTAINER_KEY,
	CONTAINER_NAME_KEY,
	DEPOT_KEY,
	DEPOT_NAME_KEY,
	DISCONTINUED_VALUE,
	TERMINAL_KEY,
	TERMINAL_NAME_KEY,
} from "./constants"
import {
	ACCESS_POINT_AREA_KEY,
	ACCESS_POINT_COORDINATES,
	ACCESS_POINT_EMAIL_KEY,
	ACCESS_POINT_NAME_KEY,
	ACCESS_POINT_STATUS_KEY,
	ACCESS_POINT_TYPE_KEY,
	EXTERNAL_ID_KEY,
	EXTERNAL_KEYS_KEY,
	PROPERTIES_KEY,
	REAL_ESTATE_CATEGORY_KEY,
	ACCESS_POINT_WTCS_KEY,
	ACCESS_POINT_FRACTION_KEY,
	ACCESS_POINT_FRACTION_DESC_KEY,
	ACCESS_POINT_WTCS_ID_KEY,
	ACCESS_POINT_WTCS_LEGACY_KEY,
	MUNICIPALITY_KEY,
	CLIENT_KEY,
} from "constants/general"
import { terminalIdGenerator, uniqueIdGenerator } from "Utils/uniqueIdGenerator"
import { addAccessPoints } from "api/addAccessPoints"
import { findClassificationSystemForWasteCode } from "Utils/api/sanity/functions"
import { FetchedWasteTypes } from "Utils/api/sanity/types"
import { ValidationCell, ValidationState } from "components/ImportComponents/types"
import { ACTIVE, DEPRECATED } from "Utils/gqlRequestTypes/generic"
import { get } from "lodash"

export const FINALIZED_STATE = {
	importing: "",
	finalized: true,
}

export const ERROR_STATE = {
	importing: "",
	error: true,
}

const getStatusDbValue = (isMWM: boolean, statusCell?: ValidationCell[], index?: number) => {
	if (!isMWM || !statusCell || !index) return ACTIVE

	const cellValue = statusCell[index].value
	if (cellValue === DISCONTINUED_VALUE) {
		return DEPRECATED
	}

	return cellValue.toUpperCase()
}

type AddToSystemProps = {
	context: IImportContext
	wasteTypes: FetchedWasteTypes | null
	refetchTerminals: () => Promise<void>
	refetchAccessParents: () => any
	isMWM: boolean
	useWasteSuctionSystem: boolean
	addAllContactPersonsToActionReportForClient: ({
		terminalIds,
	}: {
		terminalIds: string[]
	}) => Promise<boolean>
}

const getTerminalsToAdd = ({
	validationState,
	terminalNameKey,
	isMWM,
}: {
	validationState: ValidationState
	terminalNameKey: string
	isMWM: boolean
}) => {
	const terminalPropertyData = validationState[TERMINAL_KEY]!
	const terminalNameRows = terminalPropertyData[terminalNameKey]

	return terminalNameRows.map((nameCell, i) => {
		const { contactEmail, buildingCategory, area } = terminalPropertyData
		const properties = []

		if (!isMWM) {
			properties.push(
				{ key: ACCESS_POINT_EMAIL_KEY, value: contactEmail[i].value },
				{ key: REAL_ESTATE_CATEGORY_KEY, value: buildingCategory[i].value }
			)

			if (area?.[i].value) {
				properties.push({ key: ACCESS_POINT_AREA_KEY, value: area[i].value.toString() })
			}
		}

		const terminal: any = {
			[ACCESS_POINT_NAME_KEY]: nameCell.value,
			[EXTERNAL_KEYS_KEY]: [{ key: EXTERNAL_ID_KEY, value: terminalIdGenerator(nameCell.value) }],
			[ACCESS_POINT_TYPE_KEY]: "TERMINAL",
			[PROPERTIES_KEY]: properties,
		}

		return terminal
	})
}

const getContainersToAdd = (
	context: IImportContext,
	wasteTypes: FetchedWasteTypes | null,
	isMWM: boolean,
	createdAccessParents?: any
) => {
	const { validationState, selectedParentIds } = context
	const containersPropertyData = validationState[CONTAINER_KEY]!
	const containerNameRows = containersPropertyData[CONTAINER_NAME_KEY]

	return containerNameRows.map((nameCell, i) => {
		const { accessParentName, depotName, wasteTypeClassification, wasteTypeCode, containerStatus } =
			containersPropertyData
		const parentId = createdAccessParents
			? createdAccessParents.find(
					({ name }: { name: string }) =>
						name === accessParentName?.[i].value || name === depotName?.[i].value
				)?.id
			: (selectedParentIds[DEPOT_KEY] ?? selectedParentIds[ACCESS_PARENT_KEY])

		const wasteCodeId = wasteTypeCode[i].value
		const properties = [
			{
				key: ACCESS_POINT_WTCS_ID_KEY,
				value: wasteTypeClassification[i].value,
			},
			{
				key: ACCESS_POINT_WTCS_LEGACY_KEY,
				value: wasteTypeClassification[i].value,
			},
			{ key: ACCESS_POINT_FRACTION_KEY, value: wasteCodeId },
			{
				key: ACCESS_POINT_FRACTION_DESC_KEY,
				value: wasteTypes?.find(({ id }) => id === wasteCodeId)?.name,
			},
			{
				key: ACCESS_POINT_WTCS_KEY,
				value: findClassificationSystemForWasteCode(wasteTypes, wasteCodeId),
			},
		]

		const container: any = {
			[ACCESS_POINT_NAME_KEY]: nameCell.value,
			[ACCESS_POINT_TYPE_KEY]: "ACCESS_POINT",
			[ACCESS_POINT_STATUS_KEY]: getStatusDbValue(isMWM, containerStatus, i),
			[PROPERTIES_KEY]: properties,
			parent: {
				id: parentId,
			},
			[EXTERNAL_KEYS_KEY]: [{ key: EXTERNAL_ID_KEY, value: uniqueIdGenerator("uuid") }],
		}

		return container
	})
}

const addContainers = (props: AddToSystemProps, createdAccessParents?: any) => {
	const { setLoadingState, fullReset } = props.context

	const containers = getContainersToAdd(
		props.context,
		props.wasteTypes,
		props.isMWM,
		createdAccessParents
	)

	setLoadingState({
		importing: "importLabels:importingContainers",
	})
	addAccessPoints({
		accessPoints: containers,
		onStartCallBack: () => {
			props.refetchAccessParents().then(() => {
				setLoadingState(FINALIZED_STATE)
				fullReset()
			})
		},
		onFailCallBack: () => setLoadingState(ERROR_STATE),
	})
}

const addDepotContainers = (props: AddToSystemProps, createdDepots?: any) => {
	const { setLoadingState, fullReset } = props.context

	const containers = getContainersToAdd(props.context, props.wasteTypes, props.isMWM, createdDepots)

	setLoadingState({
		importing: "importLabels:importingContainers",
	})

	addAccessPoints({
		accessPoints: containers,
		onStartCallBack: () => {
			props.refetchAccessParents().then(() => {
				setLoadingState(FINALIZED_STATE)
				fullReset()
			})
		},
		onFailCallBack: () => setLoadingState(ERROR_STATE),
	})
}

const getDepotsToAdd = (context: IImportContext, createdAccessParents?: any) => {
	const { validationState, selectedParentIds } = context
	const depotsPropertyData = validationState[DEPOT_KEY]!
	const depotNameRows = depotsPropertyData[DEPOT_NAME_KEY]

	return depotNameRows.map((nameCell, i) => {
		const { accessParentName, depotStatus } = depotsPropertyData
		const parentId = createdAccessParents
			? createdAccessParents.find(
					({ name }: { name: string }) => name === accessParentName[i].value
				)?.id
			: selectedParentIds[ACCESS_PARENT_KEY]

		const container: any = {
			[ACCESS_POINT_NAME_KEY]: nameCell.value,
			[ACCESS_POINT_TYPE_KEY]: "GROUP",
			[ACCESS_POINT_STATUS_KEY]: getStatusDbValue(true, depotStatus, i),
			parent: {
				id: parentId,
			},
			[EXTERNAL_KEYS_KEY]: [{ key: EXTERNAL_ID_KEY, value: uniqueIdGenerator("uuid") }],
		}

		return container
	})
}

const addDepots = (props: AddToSystemProps, createdAccessParents?: any) => {
	const { mappingState, setLoadingState, fullReset } = props.context

	const depots = getDepotsToAdd(props.context, createdAccessParents)

	setLoadingState({
		importing: "importLabels:importingDepots",
	})
	addAccessPoints({
		accessPoints: depots,
		onStartCallBack: res => {
			props.refetchAccessParents().then(() => {
				if (mappingState[CONTAINER_KEY]?.selected) {
					addDepotContainers(props, [...res.modifiedPoints, ...(createdAccessParents ?? [])])
				} else {
					setLoadingState(FINALIZED_STATE)
					fullReset()
				}
			})
		},
		onFailCallBack: () => setLoadingState(ERROR_STATE),
	})
}

const getAccessParentsToAdd = (context: IImportContext, isMWM: boolean, createdTerminals?: any) => {
	const { validationState, selectedParentIds } = context

	const accessParentsPropertyData = validationState[ACCESS_PARENT_KEY]!
	const accessParentNameRows = accessParentsPropertyData[ACCESS_PARENT_NAME_KEY]

	return accessParentNameRows.map((nameCell, i) => {
		const { terminalName, coordinates, municipality, client, accessParentStatus } =
			accessParentsPropertyData

		const parentId = createdTerminals
			? createdTerminals.find(({ name }: any) => name === terminalName[i].value)?.id
			: selectedParentIds[TERMINAL_KEY]

		const accessParent: any = {
			[ACCESS_POINT_NAME_KEY]: nameCell.value,
			[ACCESS_POINT_TYPE_KEY]: "ACCESS_PARENT",
			parent: {
				id: parentId,
			},
			[EXTERNAL_KEYS_KEY]: [{ key: EXTERNAL_ID_KEY, value: uniqueIdGenerator("uuid") }],
		}

		if (isMWM) {
			if (coordinates?.[i]?.value) {
				accessParent.geoLocation = {
					[ACCESS_POINT_COORDINATES]: coordinates[i].value.toString(),
				}
			}
			accessParent[ACCESS_POINT_STATUS_KEY] = getStatusDbValue(isMWM, accessParentStatus, i)

			accessParent[PROPERTIES_KEY] = [
				{
					key: MUNICIPALITY_KEY,
					value: get(municipality, `[${i}].value`, "").toString(),
				},
				{
					key: CLIENT_KEY,
					value: get(client, `[${i}].value`, "").toString(),
				},
			]
		}

		return accessParent
	})
}

const addAccessParents = (props: AddToSystemProps, createdTerminals?: any) => {
	const { mappingState, setLoadingState, fullReset } = props.context

	const accessParents = getAccessParentsToAdd(props.context, props.isMWM, createdTerminals)

	setLoadingState({
		importing: "importLabels:importingAccessParents",
	})
	addAccessPoints({
		accessPoints: accessParents,
		onStartCallBack: res => {
			if (props.useWasteSuctionSystem) {
				if (mappingState[DEPOT_KEY]?.selected) {
					addDepots(props, res.modifiedPoints)
				} else if (mappingState[CONTAINER_KEY]?.selected) {
					addContainers(props, res.modifiedPoints)
				} else {
					props.refetchAccessParents().then(() => {
						setLoadingState(FINALIZED_STATE)
						fullReset()
					})
				}
			} else if (mappingState[CONTAINER_KEY]?.selected) {
				addContainers(props, res.modifiedPoints)
			} else {
				props.refetchAccessParents().then(() => {
					setLoadingState(FINALIZED_STATE)
					fullReset()
				})
			}
		},
		onFailCallBack: () => setLoadingState(ERROR_STATE),
	})
}

const addTerminals = (props: AddToSystemProps) => {
	const { mappingState, validationState, setLoadingState, fullReset } = props.context

	const terminals = getTerminalsToAdd({
		validationState,
		terminalNameKey: TERMINAL_NAME_KEY,
		isMWM: props.isMWM,
	})

	setLoadingState({
		importing: "importLabels:importingTerminals",
		finalized: false,
	})
	addAccessPoints({
		accessPoints: terminals,
		onStartCallBack: res => {
			props.refetchTerminals().then(() => {
				const terminalIds = res.modifiedPoints?.map(({ id }: { id: string }) => id)
				if (terminalIds.length && !props.isMWM) {
					props.addAllContactPersonsToActionReportForClient({ terminalIds })
				}

				if (mappingState[ACCESS_PARENT_KEY]?.selected) {
					addAccessParents(props, res.modifiedPoints)
				} else {
					setLoadingState(FINALIZED_STATE)
					fullReset()
				}
			})
		},
		onFailCallBack: () => setLoadingState(ERROR_STATE),
	})
}

export const addToSystem = (props: AddToSystemProps) => {
	const { selectedTemplate } = props.context

	switch (selectedTemplate?.key) {
		case "terminals":
			addTerminals(props)
			break

		case "accessParents":
			addAccessParents(props)
			break

		case "depots":
			addDepots(props)
			break

		case "containers":
			addContainers(props)
			break

		case "containersOnDepots":
			addDepotContainers(props)
			break
		default:
			throw new Error(`Unknown template key ${selectedTemplate?.key}`)
	}
}
