import {
	ACCESS_PARENT,
	ACCESS_POINT,
	GROUP,
	GROUP_ACCESS_POINT,
} from "admin-client-server/src/coreApi/models/Common"
import { useConfig } from "api/hooks/useConfig"
import { orderBy } from "lodash"
import { useMemo } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminalsState } from "States/Terminals"
import { FetchedWasteTypes } from "Utils/api/sanity/types"
import { trpc } from "Utils/trpc"

export interface AccessParentContainerData {
	id: string
	name: string
	externalId?: string | undefined
	accessParentId: string
	wasteTypeClassificationSystemId?: string
	wasteType: string
	wasteCode: string
	fillLevel?: string
	status?: string
	smartIntegrations?: string
	hatchTypeCode?: string
	fractionDiscriminator?: string
}

export const sortContainers = (containers?: AccessParentContainerData[]) => {
	return orderBy(containers || [], "wasteType", "asc")
}

export type AccessParentWithSortedContainers = Omit<
	ACCESS_PARENT,
	"containers" | "depots" | "customers" | "realEstateExternalId"
> & {
	containers: AccessParentContainerData[]
	depots?: (Omit<GROUP, "containers"> & {
		containers: AccessParentContainerData[]
	})[]
}

export const containerDataToProps = (
	containerData: ACCESS_POINT,
	accessParentId: string,
	wasteTypes: FetchedWasteTypes | null
): AccessParentContainerData => {
	const {
		wasteTypeClassificationSystemId,
		wasteType: { code: wasteCode },
		externalId,
		name,
		streamed,
		status,
		id,
		smartIntegrations,
	} = containerData
	const { name: wasteType = "" } = wasteTypes?.find(({ id }) => id === wasteCode) ?? {}
	return {
		id,
		name: name || "",
		externalId,
		accessParentId,
		wasteTypeClassificationSystemId,
		wasteCode,
		wasteType,
		fillLevel: streamed?.fillLevel,
		status,
		smartIntegrations,
	}
}

export const depotContainerDataToProps = (
	containerData: GROUP_ACCESS_POINT,
	accessParentId: string,
	wasteTypes: FetchedWasteTypes | null
): AccessParentContainerData => {
	const {
		wasteType: { code: wasteCode },
		name,
		status,
		id,
		hatchTypeCode,
		smartIntegrations,
		wasteTypeClassificationSystemId,
	} = containerData
	const { name: wasteType = "" } = wasteTypes?.find(({ id }) => id === wasteCode) ?? {}
	return {
		accessParentId,
		id,
		name: name || "",
		wasteCode,
		wasteType,
		status,
		hatchTypeCode,
		smartIntegrations,
		wasteTypeClassificationSystemId,
	}
}

export const buildContainerData = (
	containerData: ACCESS_POINT[] | GROUP_ACCESS_POINT[] = [],
	accessParentId: string,
	wasteTypes: FetchedWasteTypes | null,
	dataToPropsFn: (...arg: any[]) => AccessParentContainerData
) => {
	const unsortedContainers = containerData?.map((container: any) =>
		dataToPropsFn(container, accessParentId, wasteTypes)
	)
	const containers = orderBy(unsortedContainers, "wasteType", "asc")
	return containers
}

export const useAccessParents = (terminalIdParam?: string) => {
	const { currentTerminal } = useTerminalsState()
	const { wasteTypes } = useCommonEntitiesStore()
	const { isMWM } = useConfig()
	const terminalId = terminalIdParam || currentTerminal?.id

	const {
		data: unsortedAccessParents,
		isFetching: isLoading,
		isError: isErrorFetching,
		refetch,
	} = trpc.accessParents.getAllWithContainers.useQuery(
		{
			terminalId: isMWM ? undefined : terminalId,
		},
		{
			enabled: isMWM || !!terminalId,
		}
	)

	const accessParents = useMemo(
		() => orderBy(unsortedAccessParents, "name"),
		[unsortedAccessParents]
	)

	const manageAccessParentPageData = useMemo(
		() =>
			accessParents?.map(accessParent => {
				const accessParentId = accessParent.id

				const sortedContainer = buildContainerData(
					accessParent.containers,
					accessParentId,
					wasteTypes,
					containerDataToProps
				)

				const depots = accessParent.depots?.map(depot => {
					const sortedDepotContainers = buildContainerData(
						depot.containers,
						accessParentId,
						wasteTypes,
						depotContainerDataToProps
					)

					return { ...depot, containers: sortedDepotContainers }
				})
				return { ...accessParent, containers: sortedContainer, depots }
			}) ?? [],
		[accessParents, wasteTypes]
	)

	const getAccessParent = (accessParentId?: string) =>
		accessParents?.find(({ id }) => id === accessParentId)

	return {
		accessParents,
		manageAccessParentPageData,
		isLoading,
		isErrorFetching,
		getAccessParent,
		refetch,
	}
}
